import React from "react";
import "./segmentLastLayer.css";
import IconChevronRight from "../../images/vector-66.svg";
import PlaceholderImage1 from "../../images/rectangle-134@2x.png";
import PlaceholderImage2 from "../../images/rectangle-1341@2x.png";
import PlaceholderImage3 from "../../images/rectangle-1342@2x.png";
import PlaceholderImage4 from "../../images/rectangle-1343@2x.png";
import PlaceholderImage5 from "../../images/rectangle-1344@2x.png";
// import PlaceholderSmallImage5 from "../../images/rectangle-135@2x.png";

export default function SegmentLastLayer() {
  return (
    <div className="lastLayerSegment-ourSegments">
      <div className="lastLayerSegment-parent">

        <div className="lastLayerSegment-div">
          <img
            className="lastLayerSegment-child1"
            alt=""
            src={PlaceholderImage1}
          />
          <div className="lastLayerSegment-heading6">Industrial Solutions</div>
          <div className="lastLayerSegment-button18">
            <div className="lastLayerSegment-read-more5">Read More</div>
            <img
              className="lastLayerSegment-button-child6"
              alt=""
              src={IconChevronRight}
            />
          </div>
        </div>

        <div className="lastLayerSegment-div">
          <img
            className="lastLayerSegment-child1"
            alt=""
            src={PlaceholderImage2}
          />
          <div className="lastLayerSegment-heading6">Health & Personal Care</div>
          <div className="lastLayerSegment-button18">
            <div className="lastLayerSegment-read-more5">Read More</div>
            <img
              className="lastLayerSegment-button-child6"
              alt=""
              src={IconChevronRight}
            />
          </div>
        </div>

        <div className="lastLayerSegment-div">
          <img
            className="lastLayerSegment-child1"
            alt=""
            src={PlaceholderImage3}
          />
          <div className="lastLayerSegment-heading6">Livestock Solutions</div>
          <div className="lastLayerSegment-button18">
            <div className="lastLayerSegment-read-more5">Read More</div>
            <img
              className="lastLayerSegment-button-child6"
              alt=""
              src={IconChevronRight}
            />
          </div>
        </div>

        <div className="lastLayerSegment-div">
          <img
            className="lastLayerSegment-child1"
            alt=""
            src={PlaceholderImage4}
          />
          <div className="lastLayerSegment-heading6">Crop Solutions</div>
          <div className="lastLayerSegment-button18">
            <div className="lastLayerSegment-read-more5">Read More</div>
            <img
              className="lastLayerSegment-button-child6"
              alt=""
              src={IconChevronRight}
            />
          </div>
        </div>

        <div className="lastLayerSegment-div">
          <img
            className="lastLayerSegment-mask-group-icon lastLayerSegment-child1"
            alt=""
            src={PlaceholderImage5}
          />
          <div className="lastLayerSegment-heading10">Agri Produce</div>
          <div className="lastLayerSegment-button18">
            <div className="lastLayerSegment-read-more5">Read More</div>
            <img
              className="lastLayerSegment-button-child6"
              alt=""
              src={IconChevronRight}
            />
          </div>
        </div>

      </div>
    </div>
  );
}
