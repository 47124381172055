import React, { useEffect } from 'react'
import "./segmentIndustrial.css"
import Aos from "aos";
import "aos/dist/aos.css";
import PlaceHolder from "../../images/industrySolution.png"
import ChevronRight from "../../images/vector-6.svg"


export default function SegmentIndustrial() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="IndustrySolSegment-layout-192">
            <div className="IndustrySolSegment-container2">
              <img
                data-aos="fade-right" data-aos-duration="500"
                className="IndustrySolSegment-placeholder-image-icon"
                alt=""
                src={PlaceHolder}
              />
              <div className="IndustrySolSegment-content3">
                <div className="IndustrySolSegment-section-title">
                  <div className="IndustrySolSegment-content4" data-aos="fade-left" data-aos-duration="500">
                    <div className="IndustrySolSegment-heading">Industrial Solutions</div>
                    <div className="IndustrySolSegment-text1">
                      CIC’s Industrial Solutions cluster has long dominated the local market as the
                      leading supplier of binders to Sri Lanka’s paint manufacturing industry. All Binders
                      are produced locally at the state of the art plant in Panagoda on par with global
                      quality standards which gives CIC a significant competitive advantage over peers.
                    </div>
                  </div>
                </div>
                <div className="IndustrySolSegment-actions2" data-aos="fade-left" data-aos-duration="500">
                  <a href='#' className="IndustrySolSegment-button3">
                    <div className="IndustrySolSegment-button-child" />
                    <div className="IndustrySolSegment-read-more">Read More</div>
                    <img className="IndustrySolSegment-button-item" alt="" src={ChevronRight} />
                  </a>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}
