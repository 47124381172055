import React from "react"
// import Header from "../components/header"
// import Footer from "../components/footer"
import SegmentsMainBanner from "../templates/OurSegments/segmentsMainBanner"
import SegmentOverview from "../templates/OurSegments/segmentOverview"
import SegmentAgriProduce from "../templates/OurSegments/segmentAgriProduce"
import SegmentCrop from "../templates/OurSegments/segmentCrop"
import SegmentLivestock from "../templates/OurSegments/segmentLivestock"
import SegmentCare from "../templates/OurSegments/segmentCare"
import SegmentIndustrial from "../templates/OurSegments/segmentIndustrial"
import SegmentLastLayer from "../templates/OurSegments/segmentLastLayer"

export default function OurSegments() {
  return (
    <div>
      {/* <Header /> */}
      <SegmentsMainBanner />
      <SegmentOverview />
      <SegmentAgriProduce />
      <SegmentCrop />
      <SegmentLivestock />
      <SegmentCare />
      <SegmentIndustrial />
      <SegmentLastLayer />
      {/* <Footer /> */}
    </div>
  )
}
